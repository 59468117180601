import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { ThemeProvider } from '../context/theme'
import HomeDesignsSummary from '../views/HomeDesignsSummary'
import { SiteConfig } from '../types'
import {
    HomeDesignRequest,
    HomeDesign,
    HousePackage,
    HousePackageRequest,
} from '../types/HomeDesigns'
import mapHomeDesignRequest from '../utils/mapHomeDesignRequest'
import mapHousePackageRequest from '../utils/mapHousePackageRequest'
import SubscribeForm from '../components/SubscribeForm'

const defaultTheme = {
    color: {
        primary: '#001689',
        secondary: '#1dbbb2',
        third: 'grey',
    },
}

interface HomeDesignsSummaryProps {
    pageContext: {
        house: HomeDesignRequest
        config: SiteConfig
        housePackages: HousePackageRequest[]
    }
}

export default ({ pageContext }: HomeDesignsSummaryProps) => {
    const house: HomeDesign = mapHomeDesignRequest(pageContext.house)
    const housePackages: HousePackage[] = pageContext.housePackages.map(
        housePackage => mapHousePackageRequest(housePackage)
    )
    return (
        <ThemeProvider value={defaultTheme}>
            <Layout>
                <SEO title="Home Designs Summary" />
                <HomeDesignsSummary
                    house={house}
                    config={pageContext.config}
                    housePackages={housePackages}
                />
                <SubscribeForm
                    theme={defaultTheme}
                    config={pageContext.config}
                />
            </Layout>
        </ThemeProvider>
    )
}
