import React, { useContext, useState } from 'react'
import { HomeDesign, HousePackage } from '../../types/HomeDesigns'
import './style.css'
import { ThemeContext } from '../../context/theme'
import { HouseCardInfo } from '../../components/HouseCard/HouseCardInfo'
import RelatedHouseListing from '../../components/HouseCard/RelatedHouseListing'
import { Floorplan, SiteConfig } from '../../types'
import FloorplanOverview from '../../components/FloorplanOverview'
import ImageCarousel from '../../components/ImageCarousel'
import HouseHighligts from '../../components/HouseHighlights'
import EnquireForm from '../../components/EnquireForm'
import { Link } from 'react-scroll'
import TakeTour from '../../components/TakeTour'
import HyperLink from '../../components/Common/HyperLink'

// const testimonialImage = require('../../images/testimonial_customer.png')

// const testimonials: Testimonial[] = [
//     {
//         title: 'Description',
//         name: 'Helen Anderson',
//         image: testimonialImage,
//         paragraph:
//             '“Ut enim ad minim veniam, quis nost rud exercitation ullamco commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.”',
//     },
//     {
//         title: 'Description',
//         name: 'Helen Anderson',
//         image: testimonialImage,
//         paragraph:
//             '“Ut enim ad minim veniam, quis nost rud exercitation ullamco commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.”',
//     },
//     {
//         title: 'Description',
//         name: 'Helen Anderson',
//         image: testimonialImage,
//         paragraph:
//             '“Ut enim ad minim veniam, quis nost rud exercitation ullamco commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.”',
//     },
//     {
//         title: 'Description',
//         name: 'Helen Anderson',
//         image: testimonialImage,
//         paragraph:
//             '“Ut enim ad minim veniam, quis nost rud exercitation ullamco commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.”',
//     },
// ]

interface HomeDesignsSummaryProps {
    house: HomeDesign
    housePackages: HousePackage[]
    config: SiteConfig
}

export default ({ house, housePackages, config }: HomeDesignsSummaryProps) => {
    const theme = useContext(ThemeContext)
    const [active, setActive] = useState<number>(0)
    const [onEnquireButtonHover, setonEnquireButtonHover] = useState<boolean>(
        false
    )

    const relatedHouseListing = housePackages
        .map(housePackage => ({
            id: housePackage.id,
            image: housePackage.previewImage,
            name: housePackage.name,
            location: { area: 'Melbourne', suburb: housePackage.suburb },
        }))
        .slice(0, 4)

    const floorplan: Floorplan = {
        title: house.name,
        dimensions: {
            size: house.size.size,
            minDepth: house.size.lotDepth,
            minWidth: house.size.lotWidth,
        },
        images: house.floorplan
            ? {
                  groundFloor: house.floorplan.ground,
                  firstFloor: house.floorplan.first,
              }
            : {
                  groundFloor: false,
                  firstFloor: false,
              },
    }
    return (
        <section className="home-designs-summary container-fluid">
            <div className="row house-info-container">
                <div className="col-lg-8">
                    <ImageCarousel
                        headerImage={house.backgroundImage}
                        gallery={house.gallery}
                    />
                </div>
                <div className="house-info col-lg-4">
                    <HouseCardInfo
                        house={house}
                        options={{ theme }}
                        active={active}
                        showDepositCalculator={true}
                        setActive={setActive}
                    />
                    <RelatedHouseListing
                        houses={relatedHouseListing}
                        active={active}
                        options={{ theme }}
                    />
                    <HyperLink
                        style={{
                            backgroundColor: theme.color.secondary,
                        }}
                        onHoverStyle={{
                            backgroundColor: theme.color.primary,
                        }}
                        href={house.flyer}
                        className="shop-button"
                        newTab="_blank"
                        label={'Download Brochure'}
                    />
                    <Link
                        style={{
                            backgroundColor: onEnquireButtonHover
                                ? theme.color.primary
                                : 'transparent',
                            border: onEnquireButtonHover
                                ? `1px solid ${theme.color.primary}`
                                : `1px solid ${theme.color.secondary}`,
                            color: onEnquireButtonHover
                                ? 'white'
                                : theme.color.secondary,
                        }}
                        onMouseEnter={() => setonEnquireButtonHover(true)}
                        onMouseLeave={() => setonEnquireButtonHover(false)}
                        to="enquire-form"
                        className="enquire-button"
                        offset={0}
                        smooth={true}
                        duration={1000}
                    >
                        Enquire Now
                    </Link>
                </div>
            </div>
            <HouseHighligts highlights={house.highlights} options={{ theme }} />
            <div className="row">
                <FloorplanOverview floorplan={floorplan} options={{ theme }} />
            </div>
            <div className="row">
                <TakeTour tour={house.virtualTour} options={{ theme }} />
            </div>
            <div className="row">
                {/* <Testimonials testimonials={testimonials} /> */}
            </div>
            <div className="row">
                <EnquireForm
                    options={{ theme }}
                    config={config}
                    home={house.name}
                />
            </div>
        </section>
    )
}
